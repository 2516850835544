

import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import framing from '../assets/images/framing.jpg'
import LaborWarranty from '../components/LaborWarranty'

const Framing = (props) => (
    <Layout>
        <Helmet>
            <title>Framing & SIPs Installation</title>
            <meta name="description" content="We provide fast and high-quality Framing services in the South Eastern part of the USA!" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Framing & SIPs Installation</h1>
                        <p>$8 per Square Foot (Lumber Not Included)</p>
                    </header>
                    
                    <span className="image main">
                    <img src={framing} alt="" /></span><br /><br />
                    
                    <header className="major">
                        <h3>What's Included</h3>
                    </header>
                    <p>...</p><br /><br />
                    
                    <header className="major">
                        <h3>Pricing</h3>
                    </header>
                    <ul>
                        <li>How We Price It: </li>
                        <li>Competitor Pricing: </li>
                    </ul><br /><br />
                    
                    <header className="major">
                        <h3>Warranty Information</h3>
                    </header>
                    <ul>
                        <li>Materials Warranty: </li>
                        <li>{LaborWarranty}</li>
                    </ul><br />
                </div>
            </section>
        </div>

    </Layout>
)

export default Framing